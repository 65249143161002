.contact-card {
    background-color: white; /* White background for the card */
    padding: 2vh 2vw; /* Responsive padding inside the card */
    margin: 1.5vh auto; /* Responsive margin to center the card */
    max-width: 80vw; /* Limit width responsively */
    box-shadow: 0 0.4vh 0.8vh rgba(0, 0, 0, 0.1); /* Soft shadow for the card */
    border: 0.1vw solid #ddd; /* Light border around the card */
}

.h2-contact, .h3-contact, .p-contact {
    font-family: "Palatino Linotype";
}


