.h1-title {
    font-family: "Palatino Linotype";
    color: white;
}

.h3-title-benefits {
    font-family: "Palatino Linotype";
}

.card {
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1.5vw;
    margin-bottom: 1.2vh;
}

.card ul {
    list-style-type: disc;
    padding-left: 1.5vw;
}

.card ul li {
    margin-bottom: 1vh;
}

p {
    font-family: "Palatino Linotype";
}

