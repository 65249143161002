body {
    padding-bottom: 1vh;
}

/* Basic styling for card-1 */
.card-1 {
    max-width: 100vw; /* Adjust for screen width */
    margin: 2vh auto; /* Center with viewport-based margin */
    padding: 2vw; /* Inner padding */
    background-color: #ffffff;
    box-shadow: 0 0.5vh 2vh rgba(0, 0, 0, 0.1); /* Responsive shadow */
}

/* Header styles */
h1 {
    color: #3A3A3A;
    font-size: 2em; /* Make size responsive */
    margin-bottom: 1vh;
    text-align: left;
}

/* Paragraph styling */
.welcome-p p {
    color: #555;
    line-height: 1.6;
    margin-bottom: 2vh;
    text-align: left;
}

/* Ring display styling */
.ring-display {
    text-align: center;
    margin: 2vh 0;
}

/* Ring image styling */
.ring-image {
    width: 100%;
    max-width: 30vw;
    height: auto;
}

/* Chirstmas part Phots */

.holiday-link-container {
    text-align: center; /* Center the link */
    margin: 20px 0; /* Add space around the link */
  }
  
  .holiday-link {
    font-family: 'Georgia', serif; /* Elegant font */
    font-size: 18px; /* Adjust the size for readability */
    color: #d4af37; /* Gold-like color for the link */
    text-decoration: none; /* Remove underline for a clean look */
    font-weight: bold; /* Make the text stand out */
    transition: color 0.3s ease; /* Add hover effect */
  }
  
  .holiday-link:hover {
    color: #b8860b; /* Darker gold color on hover */
  }
  

/* Christmas party styling */
.holiday-display {
    background-color: #fdfdfd;
    border: 0.2vh solid #d32f2f;
    border-radius: 1vw;
    padding: 2vh;
    max-width: 22vw;
    box-shadow: 0 0.5vh 1.5vh rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    margin: 0 auto; /* Center the element horizontally */
}

.holiday {
    width: 100%;
    height: auto;
    border-radius: 0.6vw;
}

.holiday-display::before {
    font-family: 'Cursive', sans-serif;
    font-size: 1.5em;
    position: absolute;
    top: 2vh;
    left: 50%;
    transform: translateX(-50%);
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.rsvp {
    font-size: 1.5em;
    font-weight: bold;
}
  
.letter {
    transition: color 0.5s;
}

.letter-0 {
    color: red;
}

.letter-1 {
    color: green;
}

  





