
.logo-card {
    display: flex;
    align-items: flex-start;
    border-radius: 8px;
    width: fit-content;
    height: 12vh;
    margin-top: 2vh;
    position: absolute;
    top: 2vh;
    left: 8vw;
    z-index: 10;
}

.logo {
    margin-right: 1vw;
}

.logo-image {
    width: 8vw;
    height: auto;
}

.logo-company-name {
    text-align: center;
    max-width: 80vw;
    margin: 0 auto;
    margin-left: 3vw;
}

.logo-p1 {
    font-size: 2.7em;
    margin: 0;
    line-height: 1.2;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: white;
    margin-left: 0.3vw;
    font-family: "sans-serif";
    display: inline-block;
    text-align: justify;
}

.logo-p2 {
    font-size: 1.5em;
    margin: 0;
    line-height: 1.3;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: white;
    margin-left: 0.3vw;
    font-family: "Palatino Linotype";
    display: inline-block;
    text-align: justify;
}






















 
