.slideshow-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 images per row */
  gap: 0;
  padding: 1vh; /* Responsive padding */
  margin: 0 auto; /* Center the container */
  width: 85%; /* Adjust width to match navbar or desired size */
  margin-left: -0.4vw; /* Reduced negative margin for alignment */
  max-width: 98vw; /* Set a max width similar to your container */
}

.slideshow-image {
  transition: all 10s ease-in-out;
  width: 19.5vw; /* Keep reduced width for a taller look */
  height: 50vh; /* Increased height for more vertical space */
  object-fit: cover;
  box-shadow: 0 0.4vh 0.8vh rgba(0, 0, 0, 0.2); /* Soft shadow */
}




