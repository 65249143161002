.copyright {
    background-color: rgba(48, 48, 48, 1); /* Use rgba for consistent background color */
    max-width: 80vw; /* Responsive max width */
    margin: 2vh auto 0; /* Responsive margin */
    padding: 2vh 3vw; /* Responsive padding */
    text-align: left;        
}

.copyright .p {
    margin: 0;                  
    font-size: 0.9em; /* Keep the font size as is, consider rem for better responsiveness */
    color: white;           
}
