/* Navbar.css */
.nav-bar {
    background-color: rgb(48, 48, 48, 1);
    display: flex;
    justify-content: space-around;
    padding: 1vh; /* Using vh for responsive padding */
}

.nav-bar div {
    margin: 0;
}

.nav-bar a {
    font-family: "Palatino Linotype";
    color: white;
    text-decoration: none;
    padding: 0.7vh 1vw; /* Responsive padding for links */
}

.nav-bar a:hover {
    background-color: black;
    border: 0.1vw solid white; /* Adjusted border thickness for consistency */
}
