html, body {
  height: 100%;
  margin: 0; 
  padding: 0;
}

.background {
  background: linear-gradient(rgb(37, 37, 37) 33%, white 15%, white 40%, rgb(12, 114, 148) 50%);
  min-height: 150vh; /* Remains the same as it aligns with viewport height */
  display: flex;
  flex-direction: column;
  position: relative;
}

.container-1 {
  display: grid;
  place-items: center; 
}

.container-2 {
  width: 85vw;           /* Converted to viewport width */
  max-width: 78.125vw;   /* Approximately 1250px in vw */
  background-color: #687ff7;
  padding: 2vh;          /* Responsive padding */
  box-shadow: 0 0.8vh 1.6vh rgba(0, 0, 0, 0.2); /* Shadow adapted to viewport units */
  position: relative; 
  top: 3vh;              /* Adjusted position for responsiveness */
}




