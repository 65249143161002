.paypal-card {
  background-color: white;
  margin: 1.5vh auto; /* Centers element horizontally with responsive top spacing */
  padding: 2vh 2vw; /* Responsive padding */
  box-shadow: 0 0.4vh 0.8vh rgba(0, 0, 0, 0.1); /* Soft shadow */
  text-align: center;
  width: 60vw; /* Responsive width */
  max-width: 80vw; /* Limit maximum width to fit smaller screens */
}

.buttons {
  text-align: center;
}

.payment-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.h1-dues {
  font-size: 2.4vh; /* Responsive font size */
  color: #333;
  margin-bottom: 2vh;
}

.h2-dues {
  font-size: 1.6vh; /* Responsive font size */
  color: #666;
  margin-bottom: 2vh;
}

.select-container {
  width: 100%;
  max-width: 30vw; /* Responsive maximum width */
  margin-bottom: 2vh;
}

select {
  width: 100%;
  padding: 1vh; /* Responsive padding */
  border: 0.1vw solid #ccc; /* Responsive border */
  font-size: 1.6vh; /* Responsive font size */
}

  

  